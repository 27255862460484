import { cn } from '@/utils';
import LoaderIcon from 'public/assets/icons/loader.svg';

/**
 *
 * @param {{className : string}} props
 * @returns
 */

export default function Spinner({ className }) {
  return (
    <div>
      <LoaderIcon className={cn('w-10 h-10 animate-spin', className)} />
    </div>
  );
}
